
import Vue from "vue";
import MentorcastsSearch from "@/components/Mentorcast/MentorcastsSearch.vue";
import Pagination from "@/components/Pagination.vue";
import MentorcastData from "@/components/Mentorcast/MentorcastData.vue";
import RecommendedMentors from "@/components/Mentor/RecommendedMentors.vue";
import { mapGetters } from "vuex";
import MentorcastsLoading from "@/components/Mentorcast/MentorcastsLoading.vue";

export default Vue.extend({
  name: "MentorcastsListPage",
  components: {
    Pagination,
    MentorcastData,
    RecommendedMentors,
    MentorcastsSearch,
    MentorcastsLoading
  },
  data() {
    return {
      isMentorcastData: false,
      tabs: ["Upcoming", "Trending", "Attending"],
      currentTab: "Upcoming",
      filter: {
        searchVal: "",
        category_id: null,
        language_id: "",
        price: [0, 0],
        type: "Upcoming",
        available: true
      } as any,
      empty: {
        Upcoming: "No Record",
        Trending: "No Record",
        Attending: "You are not currently attending any Mentorcasts."
      },
      searching: false
    };
  },
  computed: {
    getFilter(): any {
      return this.filter;
    },
    ...mapGetters({
      mentorcasts: "mentorcast/getMentorcasts",
      filters: "filter/getFilters",
      profile: "profile/getProfile"
    })
  },
  created() {
    if (this.$route.params.categoryId) {
      this.filter.category_id = parseInt(this.$route.params.categoryId);
    }
    this.getFilters();
    this.getMentorcasts();
  },
  methods: {
    onTab(tab: any) {
      this.currentTab = tab;
      this.filter.type = tab;
      this.handleSearch(this.filter);
    },
    handleSearch({ searchVal, category_id, language_id, price }: any) {
      this.filter.searchVal = searchVal;
      this.filter.category_id = category_id;
      this.filter.language_id = language_id;
      this.filter.price = price;

      this.getFilters(searchVal);
      this.getMentorcasts();
    },
    getMentorcasts() {
      this.isMentorcastData = false;
      //@ts-ignore
      if (this.$refs["pagination"]) {
        //@ts-ignore
        this.$refs["pagination"].setPage(1);
      }

      this.searching = true;
      this.$store
        .dispatch("mentorcast/GET_ALL_MENTORCAST", {
          data: this.filter
        })
        .then(() => {
          this.isMentorcastData = true;
          this.searching = false;
        })
        .catch(() => {
          this.searching = false;
        });
    },
    getFilters(search = null) {
      this.$store
        .dispatch("filter/GET_FILTERS", {
          type: "mentorcast",
          search
        })
        .then(() => {
          this.filter.price[0] = 0;
          this.filter.price[1] = this.filters.max_price;
        });
    }
  }
});
